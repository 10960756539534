import React, { useContext, createContext, useReducer, Dispatch, useEffect } from 'react';

import {
  appReducer,
  AppActions,
  // AppActionTypes,
} from './app-reducer';

const initialState: AppState = {
  booting: true,
};

const AppStateContext = createContext<AppState>(initialState);
const AppStateDispatchContext = createContext<Dispatch<AppActions>>(() => null);

export function useAppState() {
  const context = useContext(AppStateContext);
  
  if (context === undefined) {
    throw new Error('useAppState must be used within an AuthProvider');
  }

  return context;
}

export function useAppStateDispatch() {
  const context = useContext(AppStateDispatchContext);

  if (context === undefined) {
    throw new Error('useAppStateDispatch must be used within a AuthProvider');
  }

  return context;
}

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    console.log('Bootrap application');
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};
