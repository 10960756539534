import { useEffect} from "react";
import { useTranslation } from 'react-i18next';

import './home.css';

import { useAuth } from 'contexts/auth/auth-context';
import AppHeader from 'components/app-header/app-header';

function Home() {
  const { t } = useTranslation(['home', 'app']);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const redirectURL = process.env.REACT_APP_HOME_REDIRECT_URL;

    return () => {
      // - Production / Development should redirect to: https://otavamedia.fi
      // - Local should redirect from https://localhost => https://auth-local.otavamedia.fi:4000
      if (window.location.origin !== redirectURL) {
        window.location.replace(redirectURL as string);
      }
    };
  }, []);

  return (
    <div className="page-home">
      <AppHeader />
      <div className="page-content">
        <h1>{t('brand', { ns: 'app' })}</h1>
        {!isAuthenticated &&
          <p>
            <a href={`${process.env.REACT_APP_HOME_REDIRECT_URL}/auth/login`}>{t('Login')} »</a>
          </p>
        }
      </div>
    </div>
  );
}
  
export default Home;
