import { BrowserRouter } from 'react-router-dom';

import './app.css';

import { AppProvider } from 'contexts/app/app-context';
import { AuthProvider } from 'contexts/auth/auth-context';
import AppRoutes from 'routes/app-routes';

function App() {
  return (
    <AppProvider>
      <AuthProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
