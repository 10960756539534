import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';

import ProtectedRoute from './protected-route';
import AuthRoute from './auth-route';
import Loader from 'components/loader/loader';
import AppLayout from 'modules/app/app-layout';
import Home from 'modules/home/home';

const Login = lazy(() => import('modules/login/login'));
const LoginChooser = lazy(() => import('modules/login/login-chooser'));
const LoginPassword = lazy(() => import('modules/login/login-password'));
const Register = lazy(() => import('modules/register/register'));
const Dashboard = lazy(() => import('modules/dashboard/dashboard'));
const Logout = lazy(() => import('modules/logout/logout'));
const NotFound = lazy(() => import('modules/not-found/not-found'));
const InvalidSession = lazy(() => import('modules/invalid-session/invalid-session'));
const ErrorMessage = lazy(() => import('modules/error-message/error-message'));
const ForgotPassword = lazy(() => import('modules/forgot-password/forgot-password'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'auth/session/end',
        element: <ProtectedRoute><Logout /></ProtectedRoute>,
      },
      {
        path: 'auth',
        element: <AuthRoute><Login /></AuthRoute>,
        children: [
          {
            path: 'login/:uid',
            // element: <AuthLoginRedirect />,
            element: <LoginChooser />,
          },
          {
            path: 'login/:uid/challenge-pwd',
            element: <LoginPassword />,
          },
          {
            path: 'login',
            element: <></>,
          },
        ],
      },
      {
        path: 'register',
        element: <AuthRoute><Register /></AuthRoute>,
      },
      {
        path: 'dashboard',
        element: <ProtectedRoute><Dashboard /></ProtectedRoute>,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'error-message',
        element: <ErrorMessage />,
      },
      {
        path: 'invalid-session',
        element: <InvalidSession />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ]
  },
];

export default function AppRoutes() {
  const appRoutes = useRoutes(routes);

  return (
    <Suspense fallback={<Loader />}>
      {appRoutes}
    </Suspense>
  );
}
