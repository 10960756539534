import { Navigate, useLocation } from 'react-router-dom';

import { useAppState } from 'contexts/app/app-context';
import { useAuth } from 'contexts/auth/auth-context';
import Loader from 'components/loader/loader';

export default function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { booting } = useAppState();
  const { isAuthenticated, logoutRequest } = useAuth();
  const location = useLocation();

  if (booting) {
    return <Loader />;
  }

  if (!logoutRequest && !isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} />;
  }

  return children;
}