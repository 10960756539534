import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nBackend from 'i18next-http-backend';

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG || 'fi',
    interpolation: {
      escapeValue: false,
    },
    ns: 'app',
    backend: {},
  });

export default i18n;