import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import themes from 'theme/schema.json';

export default function useTheme() {
  const [selectedTheme, setTheme] = useState(themes.default);
  const clientTheme = Cookies.get('client-theme') ?? '';

  useEffect(() => {
    const theme = Object.values(themes).find((n) => n.id === clientTheme);
    if (theme) {
      setTheme(theme);
    } else {
      setTheme(themes.default);
    }
  }, [clientTheme]);

  return {
    themes,
    selectedTheme,
  };
};