//import { Navigate } from 'react-router-dom';

import { useAppState } from 'contexts/app/app-context';
//import { useAuth } from 'contexts/auth/auth-context';
import Loader from 'components/loader/loader';

export default function AuthRoute({ children }: { children: JSX.Element }) {
  const { booting } = useAppState();
  //const { isAuthenticated } = useAuth();

  if (booting) {
    return <Loader />;
  }

/*  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace={true} />;
  }
// remove to test if this is causing login issues
  */

  return children;
}
