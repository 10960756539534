import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'components/logo/logo';
import Button from 'components/button/button-header';
import { useAuth } from 'contexts/auth/auth-context';
import back from 'assets/images/Takaisin.svg';
import Cookies from 'js-cookie';

import './app-header.css';

export default function AppHeader() {
  const navigate = useNavigate();
  const { authenticating } = useAuth();
  const headerLocation = useLocation();
  const paths = ['challenge-pwd', 'register', 'forgot-password'];
  const currentPath = headerLocation.pathname.split('/');
  function goBack() {
      if (paths.includes(currentPath.pop() || ' ')) {
        navigate( '/auth/login/' + Cookies.get('oidcId'), { replace: true });
      }
      else {
        navigate(-1)
      }
  }

  return (
    <header className="app-header">
      <Logo />
      <div className="button-back">
        <Button type="submit" disabled={authenticating} onClick={goBack}>
        <img src={back} alt="Takaisin" />
        </Button>
        </div>
      </header>
  );
}
