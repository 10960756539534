import axios, { AxiosResponse } from 'axios';
// import stringify from 'qs-stringify';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

const authCredentials = async (): Promise<AuthResponse> => {
  const result: AxiosResponse = await http.post('/');
  return result.data;
};

const verifyEmail = async (
  email: string,
): Promise<ApiResponse> => {
  const result: AxiosResponse = await http.post(
    '/verify',
    { email }
  );
  return result.data;
};

const authLogin = async (data: LoginData): Promise<AuthResponse> => {
  const result: AxiosResponse = await http.post(
    `/login/${data.uid}`,
    data,
  );
  return result.data;
};

const authLogout = async (): Promise<AuthResponse> => {
  // const result: AxiosResponse = await http.post('/logout');
  const result: AxiosResponse = await http.get('/session/end');
  return result.data;
};

// const authLogoutConfirm = async (xsrf: string): Promise<any> => {
//   const result: AxiosResponse = await http.post(
//     '/session/end/confirm',
//     stringify({
//       xsrf,
//     }),
//     {
//       headers: { 
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     }
//   );
//   return result.data;
// };

const signup = async (data: SignupData): Promise<ApiResponse> => {
  const result: AxiosResponse = await http.post(
    '/signup',
    data,
  );
  return result.data;
};

export {
  authCredentials,
  verifyEmail,
  authLogin,
  authLogout,
  signup,
  // authLogoutConfirm,
};
