import { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { decode } from 'universal-base64url';

import './logo.css';

import themes from 'theme/schema.json';

export default function Logo() {
  const themeContext = useContext(ThemeContext);
  const [logo, setLogo] = useState('');
 const navigate = useNavigate(); 

  useEffect(() => {
    setLogo(themeContext.logo);
  }, [themeContext]);

  let state = { brand: "" }

  try {
    const stateCookie = Cookies.get('state');	
    const stateCookieDecoded = decode(stateCookie);
    state = JSON.parse(stateCookieDecoded);
  } catch (e) {
  }

  const brand = Object.values(themes).find((n) => n.shortID === state.brand);

  // This is for paywall views brand logo
  if (brand && (Cookies.get('client-theme') === '003e9ac63c' || Cookies.get('client-theme') === 'paywall')) {
    const brandlogo = brand.logo;

    return (
      <div className="logo">
        <a onClick={() => navigate(-1)} ><img src={`/logos/${brandlogo}`} alt="" /></a>
      </div>
    )
  } else {

    // This is for standard brand logo 
    return (
      <>
        {logo && <div className="logo">
          <a onClick={() =>  navigate(-1)} ><img src={`/logos/${logo}`} alt="" /></a>
        </div>
        }
      </>
    )
  }
}
