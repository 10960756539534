import React, { useContext, createContext, useReducer, Dispatch, useEffect } from 'react';

import { authCredentials } from 'api/auth';
import {
  authReducer,
  AuthActions,
  AuthActionTypes,
} from './auth-reducer';
import { useAppStateDispatch } from 'contexts/app/app-context';
import { AppActionTypes } from 'contexts/app/app-reducer';

const initialState: AuthState = {
  credentials: null,
  isAuthenticated: false,
  authenticating: false,
  error: null,
};

const AuthContext = createContext<AuthState>(initialState);
const AuthDispatchContext = createContext<Dispatch<AuthActions>>(() => null);

export function useAuth() {
  const context = useContext(AuthContext);
  
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const appStateDispatch = useAppStateDispatch();

  useEffect(() => {
    dispatch({ type: AuthActionTypes.RequestAuth });

    authCredentials()
      .then((data) => dispatch({
        type: AuthActionTypes.Authenticated,
        payload: { credentials: data.credentials },
      }))
      .catch(() => {
        dispatch({
          type: AuthActionTypes.Authenticated,
          payload: { credentials: null },
        });
      })
      .finally(() => appStateDispatch({ type: AppActionTypes.BootstrapAppDone }));
  }, [dispatch, appStateDispatch]);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
