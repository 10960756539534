import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.colors.text};
  }

  a {
    color: ${({ theme }) => theme.colors.link};
  }

  .page {
  	border-color: ${({ theme }) => theme.colors.mainborder} !important;
  }
`;

export default GlobalStyles;