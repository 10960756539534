import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import 'styles/index.css';
import App from './modules/app/app';
import reportWebVitals from './reportWebVitals';
import './i18n/index';

const appContainer = document.getElementById('root');

if (appContainer) {
  const root = ReactDOMClient.createRoot(appContainer);

  root.render(
    // Note: useEffect will invoked twice but this happens only in development mode...
    // Strict mode can’t automatically detect side effects for you,
    // but it can help you spot them by making them a little more deterministic.
    // This is done by intentionally double-invoking the following functions:
    // https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
