import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import useTheme from 'theme/use-theme';
import GlobalStyles from 'theme/global-styles'

export default function AppLayout() {
  const { selectedTheme } = useTheme();

  return (
    <div className="app">
      <ThemeProvider theme={selectedTheme}>
        <GlobalStyles />
        <Outlet />
      </ThemeProvider>
    </div>
  );
}
