export enum AuthActionTypes {
  RequestAuth = 'REQUEST_AUTH',
  VerifyEmail = 'VERIFY_EMAIL',
  VerifyEmailDone = 'VERIFY_EMAIL_DONE',
  RequestLogout = 'REQUEST_LOGOUT',
  LoginSuccess = 'LOGIN_SUCCESS',
  Logout = 'LOGOUT',
  AuthError = 'AUTH_ERROR',
  Authenticated = 'AUTHENTICATED',
  RequestSignup = 'REQUEST_SIGNUP',
}

type AuthPayload = {
  [AuthActionTypes.RequestAuth]: undefined;
  [AuthActionTypes.VerifyEmail]: undefined;
  [AuthActionTypes.VerifyEmailDone]: {
    authRef: AuthRefData;
  };
  [AuthActionTypes.RequestLogout]: undefined;
  [AuthActionTypes.LoginSuccess]: {
    credentials: object | null;
  };
  [AuthActionTypes.Logout]: undefined;
  [AuthActionTypes.AuthError]: {
    error: string;
    code?: string;
  };
  [AuthActionTypes.Authenticated]: {
    credentials: object | null;
  };
  [AuthActionTypes.RequestSignup]: {
    authRef: AuthRefData;
  };
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<
  AuthPayload
>];

export const authReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case AuthActionTypes.RequestAuth:
      return {
        ...state,
        authenticating: true,
      };
      
    case AuthActionTypes.Authenticated:
      return {
        ...state,
        authenticating: false,
        isAuthenticated: !!action.payload.credentials,
        credentials: action.payload.credentials,
      };

    case AuthActionTypes.VerifyEmail:
      return {
        ...state,
        authenticating: true,
      };

    case AuthActionTypes.VerifyEmailDone:
      return {
        ...state,
        authRef: action.payload.authRef,
        authenticating: false,
        error: null,
      };

    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        isAuthenticated: !!action.payload.credentials,
        credentials: action.payload.credentials,
        authenticating: false,
        logoutRequest: null,
        error: null,
      };

    case AuthActionTypes.RequestSignup:
      return {
        ...state,
        authRef: action.payload.authRef,
        authenticating: false,
        error: null,
      };

    case AuthActionTypes.RequestLogout:
      return {
        ...state,
        logoutRequest: new Date().getTime(),
      };

    case AuthActionTypes.Logout:
      return {
        ...state,
        isAuthenticated: false,
        credentials: null,
        authenticating: false,
        error: null,
      };
      
    case AuthActionTypes.AuthError:
      return {
        ...state,
        authenticating: false,
        error: {
          message: action.payload.error,
          code: action.payload.code,
        },
        
      };

    default:
      // throw new Error(`Unhandled action type: ${action.type}`);
      return state;
  }
};