export enum AppActionTypes {
  BootstrapAppDone = 'BOOTSTRAP_APP_DONE',
}

type AppPayload = {
  [AppActionTypes.BootstrapAppDone]: undefined;
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<
  AppPayload
>];

export const appReducer = (state: AppState, action: AppActions) => {
  switch (action.type) {
    case AppActionTypes.BootstrapAppDone:
      return {
        ...state,
        booting: false,
      };
    
    default:
      // throw new Error(`Unhandled action type: ${action.type}`);
      return state;
  }
};